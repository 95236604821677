/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a DELETE request to the following endpoint:"), "\n", React.createElement(_components.p, null, "DELETE ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments/processing-terminals/%7BprocessingTerminalId%7D/secure-tokens/%7BsecureTokenId%7D"
  }, "https://api.uat.payroc.com/v1/payments/processing-terminals/{processingTerminalId}/secure-tokens/{secureTokenId}"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.p, null, "No response body."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
