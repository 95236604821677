/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a PATCH request to the following endpoint:"), "\n", React.createElement(_components.p, null, "PATCH ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments/processing-terminals/%7BprocessingTerminalId%7D/secure-tokens/%7BsecureTokenId%7D"
  }, "https://api.uat.payroc.com/v1/payments/processing-terminals/{processingTerminalId}/secure-tokens/{secureTokenId}")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\t\"secureTokenId\": \"MREF_fe532d13-d330-4872-b646-61750144030bG4\",\n\t\"processingTerminalId\": \"3204001\",\n\t\"customer\": {\n\t\t\"contactMethods\": [\n\t\t\t{\n\t\t\t\t\"type\": \"email\",\n\t\t\t\t\"value\": \"EMAIL3@gmail.com\"\n\t\t\t}\n\t\t]\n\t},\n\t\"source\": {\n\t\t\"type\": \"card\",\n\t\t\"cardholderName\": \"Test Card 13 Uat Usa\",\n\t\t\"cardNumber\": \"374245*****1003\",\n\t\t\"expiryDate\": \"1224\"\n\t},\n\t\"token\": \"2967538321403871\",\n\t\"status\": \"cvv_validated\"\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
